import React from "react";
import { strToTitleCase } from "@utils/string";

import "./index.sass";

const MarketplaceIcon = ({ marketplace, className, realColor, noText, noHover }) => (
    <div className={`marketplace-icon ${marketplace}${noHover ? " nohover": ""}${realColor ? " realcolor": ""}${className ? " " + className : ""}`}>
        <span className="marketplace-icon-icon"></span>
        {!noText && (
            <span className="marketplace-icon-title">
                {strToTitleCase(marketplace)}
            </span>
        )}
    </div>
);

export default MarketplaceIcon;