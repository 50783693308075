import React, { useState, Fragment } from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { getSrc } from "gatsby-plugin-image"
import InfiniteScroll from "react-infinite-scroller";

import useMediaQuery from '@hooks/useMediaQuery'

import Sticky from "@components/Sticky";
import Layout from "@components/Layout";
import Breadcrumb from "@components/Breadcrumb";
import StarRating from "@components/StarRating";
import ImageCarousel from "@components/ImageCarousel";
import PreviewCompatibleImage from "@components/PreviewCompatibleImage";
import Accordion from "@components/Accordion";
import MarketplaceIcon from "@components/MarketplaceIcon";
import ProgressBar from "@components/ProgressBar";
import MarkdownIntl  from "@components/MarkdownIntl";
import SearchFilterItem from "@components/SearchFilter/SearchFilterItem";
import NoResult from "@components/NoResult";

import "./index.sass";
import { productToIntlParam, postToProduct, combineVariantToProduct, nodeToProductCategory } from "@utils/product";
import { formatIntl,formatRelativeDateIntl } from "@utils/intl"
import { strToTitleCase } from "@utils/string"

const REVIEW_PER_ROW = 10;

const generateAccordionItems = (intl, product) => {
  const intlParam = productToIntlParam(intl, product);
  const accordionItems = [];

  if (product.description) {
    accordionItems.push({
      title: <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "description"}} />,
      value: "description",
      children: (
        <span className="product-page-info-description">
          <MarkdownIntl message={formatIntl(intl, product.description)} param={intlParam} />
        </span>)
    });
  }

  if(product.detail) {
    accordionItems.push({
      title: <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "detail"}} />,
      value: "detail",
      children: (
        <span className="product-page-info-detail" >
          <MarkdownIntl message={formatIntl(intl, product.detail)} param={intlParam} />
        </span>)
    });
  } 

  if (product.function) {
    accordionItems.push({
      title: <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "function"}} />,
      value: "function",
      children: (
        <span className="product-page-info-function">
            <MarkdownIntl message={formatIntl(intl, product.function)} param={intlParam} />
        </span>)
    });
  }   
  
  if (product.faq) {
    accordionItems.push({
      title: <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "faq"}} />,
      value: "faq",
      children: (
        <div className="product-page-info-faq">
          {product.faq.map(({question, answer}, index) => (
            <Fragment key={index}>
              <span className="product-page-info-faq-question">
                <MarkdownIntl message={formatIntl(intl, question)} param={intlParam} />
              </span>
              <span className="product-page-info-faq-answer">
                <MarkdownIntl message={formatIntl(intl, answer)} param={intlParam} />
              </span>
            </Fragment>
          )) }
        </div>)
    });
  }

  return accordionItems
}

const filterProductActiveReview = (product, filter) => {
  let activeReview = (product.review && product.review.reviews) || [];
  if (filter.sort == "newest") {
    activeReview = activeReview.sort((reviewA, reviewB) => new Date(reviewB.date) - new Date(reviewA.date))
  } else {
    activeReview = activeReview.filter((review) => review.marketplace == filter.sort);
  }
  product.activeReview = activeReview;
  return product
}

const getReviewSort = (intl,activeSort, handleReviewSortChange, review) => {
  
    const sortFilter = {
        title: "Sort",
        type: "select",
        noContainer:true,
        data: {
            name: "sort",
            value: [
                {
                    title: intl.formatMessage({
                        id: "ProductPage_REVIEW_SORT"
                    }, {
                        value: "newest"
                    }),
                    value: "newest",
                    isActive: !activeSort || activeSort == "" || activeSort == "newest",
                }
            ]
        },
        onChange: handleReviewSortChange
    };

    _.forEach(review.marketplace_summary, (val, marketplace) => {
      sortFilter.data.value.push({
        title: strToTitleCase(marketplace),
        value: marketplace,
        isActive: activeSort == marketplace,
      });
    });

    return sortFilter;
}

// eslint-disable-next-line
export const ProductPageTemplate = ({
  product,
  category
}) => {
  const intl = useIntl();
  const [isHydrated, mediaQuery] = useMediaQuery();
  const isMobile = mediaQuery({
    query: '(max-width: 1000px)'
  });
  const hydrated = isHydrated()

  const [activeVariant, setActiveVariant] = useState(-1);
  const [activeReviewSort, setActiveReviewSort] = useState("newest");


  if (activeVariant > -1) {
    const activeVariantProduct = product.variants[activeVariant]
    product = combineVariantToProduct(product, activeVariantProduct);
  }

  const handleVariantClick = (e) => {
    const { value } = e.currentTarget;
    let nextVal = value
    if (activeVariant == value) {
      nextVal = -1;
    }
    setActiveVariant(nextVal);
  }
  
  const handleReviewSortChange = (e) => {
    const {
      value
    } = e.target;

    product = filterProductActiveReview(product, {
      sort: value
    });

    setActiveReviewSort(value);
    setReviewOffset(Math.min(REVIEW_PER_ROW, product.activeReview.length));
  }

  const handleLoadMoreReview = () => {
    let newOffset = 0;
    if (newOffset < product.activeReview.length) {
      newOffset = Math.min(reviewOffset + REVIEW_PER_ROW, product.activeReview.length);
    }
    setReviewOffset(newOffset);
  }

  product = filterProductActiveReview(product, {
    sort: activeReviewSort
  });

  const [reviewOffset, setReviewOffset] = useState(Math.min(REVIEW_PER_ROW, product.activeReview.length));

  const accordionItems = generateAccordionItems(intl, product);

  const reviewSort = getReviewSort(intl, activeReviewSort, handleReviewSortChange, product.review);

  const hasMoreReview = reviewOffset < product.activeReview.length  

  return (
    <div key={hydrated} className={`product-page${isMobile? " is-mobile": ""}`}>
      <div className="product-page-header">
        <Sticky enabled={!isMobile} top=".top-header" bottomBoundary=".product-page">
          <div className="product-page-header-wrapper">
            {category && <Breadcrumb breadcrumbs={[{
              title: "Products",
              link: "/search/product"
            },
            {
              title: formatIntl(intl, category.intl_title),
              link: category.slug
            },
            {
              title: formatIntl(intl, product.intl_title)
            }
          ]} />}
          </div>
        </Sticky>
      </div>
      <div className="product-page-top-content">
        <div className="product-page-top-content-left">
          <Sticky enabled={!isMobile} top={[".top-header", ".product-page-header-wrapper"]} bottomBoundary=".product-page-top-content">
            <div className="product-page-image">
              <div className="product-page-image-wrapper">
                  <ImageCarousel images={product.images}  alt={formatIntl(intl, product.intl_title)} isShowPagination />
                    {product.stock <= 0 && (
                        <div className="product-page-image-empty-stock-dimmer"></div> 
                    )}
              </div>
            </div>
          </Sticky>
        </div>
        <div className="product-page-top-content-right">
          <div className="product-page-info-header">
            <Sticky enabled={!isMobile} top={[".top-header", ".product-page-header-wrapper"]}  bottomBoundary=".product-page-top-content">
              <div className="product-page-info-section product-page-info-header-wrapper">
                <div className="product-page-info-title-wrapper">
                  <h2 className="product-page-info-title">{formatIntl(intl, product.intl_title)}</h2>
                  <div className="product-page-label-wrapper">
                          {product.stock <= 0 && (
                              <span className="product-page-label product-page-label-empty-stock">
                                  <span className="product-page-label-tooltip">
                                      <FormattedMessage id="ProductPage_LABEL_EXPAND" values={{type: "empty_stock"}} />
                                  </span>
                              </span>
                          )}
                          {product.stock > 0 && product.is_new && (
                              <span className="product-page-label product-page-label-new">
                              </span>
                          )}
                      </div>
                </div>
                <div className="product-page-info-performance">
                  <StarRating className="product-page-info-rating" rating={product.star_rating} count={product.review_count} zeroText="-" />
                </div>
                <div className="product-page-info-price">
                    {product.price.discount_price && 
                        <span className="product-page-info-price-discount">
                            <span className="product-page-info-price-discount-percent">{product.discount_percent}%</span>
                            <span className="product-page-info-price-discount-price">{product.price_formatted.strike_price}</span>
                        </span>
                    }
                    <span className="product-page-info-price-normal">{product.price_formatted.final_price}</span>
                </div>
              </div>
            </Sticky>
          </div>
          {product.variants && product.variants.length > 0 && (
            <div className="product-page-info-section product-page-info-variants">
                <span className="product-page-info-section-title product-page-info-variants-title"><FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "variant"}} /></span>
                <div className="product-page-info-section-content product-page-info-variants-content">
                  {product.variants.map(({intl_title, image, stock }, index) => (
                      <button value={index} onClick={handleVariantClick}  className={`product-page-info-variants-item${ activeVariant == index ? " active": ""}`}>
                          <div className="product-page-info-variants-item-img">
                            <PreviewCompatibleImage 
                              imageInfo={{
                                image: image,
                                alt: formatIntl(intl, intl_title),
                              }}
                            />
                            {stock <= 0 && 
                                <span className="product-page-variants-item-empty-stock-dimmer"></span>  
                            }
                          </div>
                          <span className="product-page-info-variants-item-title">{formatIntl(intl, intl_title)}</span>
                      </button>
                  ))}
                </div>
            </div>
          )}
          <div className="product-page-info-marketplace">
            <Sticky enabled={!isMobile} top={[".top-header", ".product-page-header-wrapper", ".product-page-info-header-wrapper"]}  bottomBoundary=".product-page-top-content">
              <div className="product-page-info-section product-page-info-marketplace-wrapper">
                <span className="product-page-info-section-title product-page-info-marketplace-title"><FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "marketplace"}} /></span>
                <div className="product-page-info-section-content product-page-info-marketplace-content">
                    {_.map(product.links, (link, marketplace) => (
                        <a key={`${marketplace}-${link}`} target="_blank" href={link} className={`product-page-info-marketplace-item ${marketplace}`}>
                          <MarketplaceIcon marketplace={marketplace} />
                        </a>
                    ))}
                </div>
              </div>
            </Sticky>
          </div>
          <Accordion items={accordionItems} />
        </div>
      </div>
      <div className="product-page-bottom-content">
        {(product.star_rating > 0) &&
          <div className="product-page-bottom-left">
              <div className="product-page-info-review-summary">
                <Sticky enabled={!isMobile} top={[".top-header",".product-page-header-wrapper"]} bottomBoundary=".product-page-bottom-content">
                  <div className="product-page-info-review-summary-wrapper">
                      <div className="product-page-info-review-summary-title">
                          <span className="product-page-info-review-summary-title-value">{product.star_rating_formatted}</span>
                          <span className="product-page-info-review-summary-title-max">/ 5.0</span>
                      </div>
                      <div className="product-page-info-review-summary-rating">
                          <StarRating rating={product.star_rating} noText noCount />
                      </div>
                      <div className="product-page-info-review-summary-marketplace">
                        {product.review && product.review.star_rating > 0 && product.review.marketplace_summary && (
                            _.map(product.review.marketplace_summary, (value, marketplace) => (
                              <div key={marketplace} className="product-page-info-review-summary-marketplace-item">
                                <MarketplaceIcon className="product-page-info-review-summary-marketplace-item-icon" marketplace={marketplace} noText noHover />
                                <ProgressBar progress={value.star_rating / 50 * 100} color="yellow" className="product-page-info-review-summary-marketplace-item-bar" />
                                <span className="product-page-info-review-summary-marketplace-item-text">
                                  <span className="product-page-info-review-summary-marketplace-item-text-rating">
                                    {(value.star_rating/10).toFixed(1)}
                                  </span>
                                  <span className="product-page-info-review-summary-marketplace-item-text-count">
                                    ({value.review_count})
                                  </span>
                                </span>
                              </div>
                            ))
                        )}
                      </div>
                  </div>
                </Sticky>
              </div>
          </div>
        }
        <div className="product-page-bottom-middle">
            <div className="product-page-info-review">
              <div className="product-page-info-review-header">
                <Sticky top={[".top-header", ".product-page-header-wrapper"]}  bottomBoundary=".product-page-bottom-content" >
                  <div className="product-page-info-review-header-wrapper">
                    <div className="product-page-info-review-header-left">
                      <span className="product-page-info-review-title">
                        <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "customer_review"}} />
                      </span>
                      <span className="product-page-info-review-subtitle">
                        {product.activeReview && product.activeReview.length > 0 && <FormattedMessage id="ProductPage_REVIEW_RESULT" values={{total: product.activeReview.length, offset: reviewOffset}} />}
                      </span>
                    </div>
                    <div className="product-page-info-review-header-right">
                      <span className="product-page-info-review-sort-text">
                            <FormattedMessage id="ProductPage_REVIEW_SORT_TEXT"/>
                      </span>
                      <div className="product-page-info-review-sort-input">
                            <SearchFilterItem {...reviewSort} />
                      </div>
                    </div>
                  </div>
                </Sticky>
              </div>
              <div className="product-page-info-review-content">
                  {product.activeReview && product.activeReview.length > 0 && 
                    <InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMoreReview}
                    hasMore={hasMoreReview}
                    loader={<div className="search-widget-result-loader"></div>}>
                    {product.activeReview && product.activeReview.length > 0 && product.activeReview.slice(0,reviewOffset).map((review, index) => (
                        <div key={index} className="product-page-info-review-item">
                          <div className="product-page-info-review-item-header">
                            <div className="product-page-info-review-item-header-superheader">
                              <StarRating rating={review.star_rating} noCount />
                              <span className="product-page-info-review-item-marketplace">{strToTitleCase(review.marketplace)}</span>
                            </div>
                            <div className="product-page-info-review-item-header-author">
                              <MarketplaceIcon realColor className="product-page-info-review-item-icon" marketplace={review.marketplace} noText noHover />
                              <span className="product-page-info-review-item-header-author-text">{review.author}</span>
                            </div>
                            <div className="product-page-info-review-item-header-subheader">
                              <span className="product-page-info-review-item-header-date">{formatRelativeDateIntl(intl, review.date)}</span>
                              {review.variant && <span className="product-page-info-review-item-header-variant"> | Varian: {review.variant}</span>}
                            </div>
                          </div>
                          {review.content && 
                            <div className="product-page-info-review-item-content">
                              <MarkdownIntl message={review.content} />
                            </div>
                          }
                          {review.images && review.images.length > 0 &&
                            <div className="product-page-info-review-item-images">
                              {review.images.map((image, index) =>
                                <PreviewCompatibleImage
                                  key={index}
                                  className="product-page-info-review-item-image"
                                  imageInfo={{
                                    image: image,
                                    alt: `review-${index}`,
                                  }}
                                />
                              )}
                            </div>
                          }
                        </div>
                    ))}
                  </InfiniteScroll>}
                  {!product.activeReview || product.activeReview.length <= 0 &&(
                    <div className="product-page-info-review-no-result">
                      <div className="product-page-info-review-no-result-icon">
                        <NoResult />
                      </div>
                      <div className="product-page-info-review-no-result-text">
                        <FormattedMessage id="ProductPage_REVIEW_NORESULT" values={{sort: strToTitleCase(activeReviewSort) }} />
                      </div>
                    </div>
                  )}
              </div>
            </div>
        </div>
        <div className="product-page-bottom-right">
          <div className="product-page-info-summary">
            <Sticky enabled={!isMobile} top={[".top-header", ".product-page-header-wrapper"]} bottomBoundary=".product-page-bottom-content">
              <div className="product-page-info-summary-wrapper"> 
                <div className="product-page-info-summary-header">
                    <div className="product-page-info-title-wrapper">
                      <span className="product-page-info-title">{formatIntl(intl, product.intl_title)}</span>
                          <div className="product-page-label-wrapper">
                              {product.stock <= 0 && (
                                  <span className="product-page-label product-page-label-empty-stock">
                                      <span className="product-page-label-tooltip">
                                          <FormattedMessage id="ProductPage_LABEL_EXPAND" values={{type: "empty_stock"}} />
                                      </span>
                                  </span>
                              )}
                              {product.stock > 0 && product.is_new && (
                                  <span className="product-page-label product-page-label-new">
                                  </span>
                              )}
                          </div>
                    </div>
                    <div className="product-page-info-performance">
                      <StarRating className="product-page-info-rating" rating={product.star_rating} count={product.review_count} zeroText="-" />
                    </div>
                    <div className="product-page-info-price">
                        {product.price.discount_price && 
                            <span className="product-page-info-price-discount">
                                <span className="product-page-info-price-discount-percent">{product.discount_percent}%</span>
                                <span className="product-page-info-price-discount-price">{product.price_formatted.strike_price}</span>
                            </span>
                        }
                        <span className="product-page-info-price-normal">{product.price_formatted.final_price}</span>
                    </div>
                </div>
                <div className="product-page-info-summary-content">
                    <div className="product-page-info-summary-content-title product-page-info-section-title">
                        <FormattedMessage id="ProductPage_SECTION_TITLE" values={{title: "marketplace"}} />
                    </div>
                    <div className="product-page-info-summary-marketplace">
                      {_.map(product.links, (link, marketplace) => (
                          <a key={`${marketplace}-${link}`} target="_blank" href={link} className={`product-page-info-marketplace-item ${marketplace}`}>
                            <MarketplaceIcon marketplace={marketplace} />
                          </a>
                      ))}
                    </div>
                </div>
              </div>
            </Sticky>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductPage = ({ data }) => {
  const intl = useIntl();
  let product = postToProduct(data.markdownRemark);
  let category = nodeToProductCategory(data.allMarkdownRemark.nodes[0]);
  const title = intl.formatMessage( {id: "ProductPage_PAGETITLE"}, {product_title: formatIntl(intl, product.intl_title)});
  const customOgImage = product.images ? getSrc(product.images[0]) : undefined
  return (
    <Layout customTitle={title} customOgImage={customOgImage} isHeaderFixed isFooterFixed>
      <ProductPageTemplate product={product} category={category}/>
    </Layout>
  );
};


export default ProductPage;

export const productPageQuery = graphql `
  query ProductPage($id: String!, $productCategories: [String]!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {
            eq: "ProductCategoryPage"
          },
          productCategory: {
            in: $productCategories
          }
          IS_DEFAULT: {
            ne: true
          }
          IS_HIDDEN: {
            ne: true
          }
        }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          intl_title {
            en
            id
          }
          productCategory
        }
      }
    }
    markdownRemark(id: {
      eq: $id
    }) {
      id
      fields {
        slug
      }
      frontmatter {
        sku
        intl_title {
          en
          id
        }
        is_new
        images {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        productCategories
        price {
          normal_price
          discount_price
        }
        stock
        links {
          tokopedia
          shopee
          tiktok_shop
          blibli
          lazada
          bukalapak
        }
        description {
          en
          id
        }
        detail {
          en
          id
        }

        function {
          en
          id
        }
        faq {
          question {
            en
            id
          }
          answer {
            en
            id
          }
        }
        variants {
          intl_title {
            en
            id
          }
          sku
          stock
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          price {
            normal_price
            discount_price
          }
        }
        review {
          star_rating
          review_count
          marketplace_summary {
            tokopedia {
              star_rating
              review_count
            }
            shopee {
              star_rating
              review_count
            }
            tiktok_shop {
              star_rating
              review_count
            }
            blibli {
              star_rating
              review_count
            }
            lazada {
              star_rating
              review_count
            }
            bukalapak {
              star_rating
              review_count
            }
          }
          reviews {
            author
            star_rating
            marketplace
            date
            variant
            content
            images {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;