import React, { Fragment } from "react";
import { Link } from "gatsby-plugin-react-intl";

import "./index.sass";

const Breadcrumb = ({ breadcrumbs }) => (
    <div className="breadcrumb">
        {breadcrumbs.map(({ title, link, isExternal }, index) => (
            <Fragment key={index}>
                {!isExternal && link &&
                    <Link to={link} className="breadcrumb-item">
                        {title}
                    </Link>
                }
                {isExternal && link &&
                    <a href={link} className="breadcrumb-item">{title}</a>
                }
                {!link &&
                    <span className="breadcrumb-item">
                        {title}
                    </span>
                }
            </Fragment>
        ))}
    </div>
);

export default Breadcrumb;