import React from "react";

import "./index.sass";

const ProgressBar = ({ className, color, progress }) => (
    <div className={`progress-bar${color ? " "+color: ""}${className ? " " + className : ""}`}>
        <div className="progress-bar-fill" style={{width: progress + "%"}}></div>
    </div>
);

export default ProgressBar;