const strToTitleCase = (str) => (
    str.replace(/-|_/g, " ").split(" ").map(word => (
        word[0].toUpperCase() + word.slice(1)
    )).join(" ")
);

export {
    strToTitleCase
};

